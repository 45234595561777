<template>
    <h3>Kontaktoplysninger</h3>
    <div>
        <p>
            Inden oprettelsen af Facebook Feedet kan færdiggøres skal vi bruge dine kontaktoplysninger.
        </p>     
    </div>    
        <div>
            <label class="label label-default">Fulde navn *</label>
            <input 
            :value="formValue.fullName"
            type="text" 
            name="fullName" 
            id="fullName" 
            class="form-control shadow-none"
            @input="onChange"
            placeholder="Dit fulde navn"
            />
            <div class="input-errors" v-for="error of v$.formValue.fullName.$errors" :key="error.$uid">
                <p class="alert alert-danger mt-2">{{ error.$message }}</p>
            </div>            
        </div>
        <div>
            <label class="label label-default">Email *</label>
            <input 
            :value="formValue.email" 
            type="email" 
            name="email" 
            id="email" 
            class="form-control shadow-none"
            @input="onChange"
            placeholder="Din email adresse"
            />
            <div class="input-errors" v-for="error of v$.formValue.email.$errors" :key="error.$uid">
                <p class="alert alert-danger mt-2">{{ error.$message }}</p>
            </div>
        </div>
        <div>
            <label class="label label-default">Telefonnummer *</label>
            <input 
            :value="formValue.phoneNumber" 
            type="text" 
            name="phoneNumber" 
            id="phoneNumber" 
            class="form-control shadow-none" 
            @input="onChange"
            placeholder="Dit telefonnummer"
            />
            <div class="input-errors" v-for="error of v$.formValue.phoneNumber.$errors" :key="error.$uid">
                <p class="alert alert-danger mt-2">{{ error.$message }}</p>
            </div>
        </div>
         <div>
            <label class="label label-default">Firmanavn *</label>
            <input 
            :value="formValue.companyName" 
            type="text" 
            name="companyName" 
            id="companyName" 
            class="form-control shadow-none"
            placeholder="Firmanavn"
            @input="onChange"
            >
            <div class="input-errors" v-for="error of v$.formValue.companyName.$errors" :key="error.$uid">
                <p class="alert alert-danger mt-2">{{ error.$message }}</p>
            </div>
        </div>
        <div>
            <label class="label label-default">Faktureringsadresse *</label>
            <input 
            :value="formValue.billingAddress" 
            type="text" 
            name="billingAddress" 
            id="billingAddress" 
            class="form-control shadow-none" 
            placeholder="Faktureringsadresse"
            @input="onChange"
            >
            <div class="input-errors" v-for="error of v$.formValue.billingAddress.$errors" :key="error.$uid">
                <p class="alert alert-danger mt-2">{{ error.$message }}</p>
            </div>
        </div>
        <div>
            <label class="label label-default">Postnummer og by *</label>
            <div class="row">
                <div class="col-4">
                    <input 
                :value="formValue.postalCode" 
                type="text" 
                name="postalCode" 
                id="postalCode" 
                class="form-control shadow-none" 
                placeholder="Postnummer"
                @input="onChange"
                >
                <div class="input-errors" v-for="error of v$.formValue.postalCode.$errors" :key="error.$uid">
                <p class="alert alert-danger mt-2">{{ error.$message }}</p>
            </div>
                </div>
                <div class="col-8">
                    <input 
                :value="formValue.city" 
                type="text" 
                name="city" 
                id="city" 
                class="form-control shadow-none" 
                placeholder="By"
                @input="onChange"
                >
                <div class="input-errors" v-for="error of v$.formValue.city.$errors" :key="error.$uid">
                <p class="alert alert-danger mt-2">{{ error.$message }}</p>
            </div>
                </div>              
                
            </div>
            </div>
        <div>
            <label class="label label-default">Evt. besked / andet</label>
            <textarea 
            :value="formValue.message" 
            type="text" 
            name="message" 
            id="message" 
            class="form-control shadow-none" 
            @input="onChange"
            ></textarea>
        </div>
       
    <br>
    <div>
    <p v-if="submitError" class="alert alert-danger">
        {{ submitError }}
    </p>
    <p v-if="submitSuccess" class="alert alert-success">
        Tak for din bestilling - du modtager om lidt en bekræftelse på mail (Modtager du mod forventning ikke noget i din indbakke, bør du kigge i ”spam” mappen).
    </p>
    <p v-if="submitSuccess && isLoadingFetchingPosts" class="alert alert-info mt-2">
        Henter opslag fra Facebook side, vent venligst... <easy-spinner v-if="isLoadingFetchingPosts" />
    </p>
    <button v-if="!submitSuccess" :disabled="disableSubmit || this.formValue.isFormSubmittet" @click="submitForm" class="btn custom-btn">Bestil <easy-spinner v-if="isLoading" /></button>
  </div>
  <div v-if="widgetCreated">
      <p class="alert alert-success">
          Tillykke! Jeres EasyFacebook er nu oprettet i vores system og er klar til opsætning på jeres hjemmeside. Der er sendt en email til {{ formValue.email }} med alle informationer om hvordan I kan opsætte og tilpassen EasyFacebook så den passer ind på jeres hjemmeside. Nedenfor kan du se en demo af hvordan det kommer til at se ud.
      </p>
  
       <div class="row mt-3">
        <h5 class="mb-3">Demo</h5>
        <div class="widget-row">

          <EasyFacebookWidgetDemo :easyFacebookConfig="easyFacebookConfig" />
     
    </div>
  </div>  
  </div>
  <div v-if="createWidgetError && !isLoadingFetchingPosts">
      <p class="alert alert-danger">
          Der opstod en fejl da vi hentede opslag fra Facebook siden. Er du sikker på der er oprettet opslag på siden? Du kan prøve igen eller kontakte os på web@autoit.dk hvis problemet fortsætter.
      </p>
      <button @click="fetchPostsAndCreateWidget(newEasyFacebookWidget)" class="btn custom-btn">Hent opslag <easy-spinner v-if="isLoadingFetchingPosts" /></button>
    </div>   
    <br>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, email, minLength, numeric } from '@vuelidate/validators'
import SomeWebApiEndpoints from '../../services/some-web-api-service'
import EasyFacebookWidgetDemo from '../EasyFacebookWidgetDemo.vue'

export default {
    name: 'ContactInformation',
    components: {
        EasyFacebookWidgetDemo
    },
    props: {        
        formValue: {
            fbUserId: String,                
            fbPageName: String,
            fbPageId: String,
            fbPageAccessToken: String,
            isFbPageSelected: Boolean,
            fullName: String,
            email: String,
            phoneNumber: Number,
            message: String,
            companyName: String,
            billingAddress: String,
            postalCode: String,
            city: String,
            fbPageName: String,
            managedPages: {
                data: Array,
                success: Boolean,
                message: String
            },
            longLivedUserAccessTokenResponse: {
                    data: {
                        accessToken: String
                    },
                    success: Boolean,
                    message: String
            },
            isLoggedIn: Boolean,
            shortLivedUserAccessToken: String,
            haveAPageBeenSelected: Boolean,
            isFormSubmittet: Boolean
        }     
    },
    emits: ['formValueChange'],   
    data() {
        return {
            v$: useValidate(),
            submitError: '',
            submitSuccess: false,
            isLoading: false,
            disableSubmit: false,
            isLoadingFetchingPosts: false,
            widgetCreated: false,
            createWidgetError: false,
            newEasyFacebookWidget: "",
            easyFacebookConfig: {
                easyFacebookEnv: 'Production',
                easyFacebookApiKey: ''
            }
        }  
    },
    methods: {
        onChange(e) {
            this.$emit('formValueChange', {
                label: 'registerEasyFacebook',
                data: {
                    ...this.formValue,
                    [e.target.name]: e.target.value
                }
            })
        },
        submitForm() {
            this.isLoading = true
            this.submitFormValidate() 
        }, 
        submitFormValidate() {
            this.v$.$validate()
            if (!this.v$.$error) {
                
                this.disableSubmit = true

                let newEasyFacebook = {
                    pageId: this.formValue.fbPageId,
                    name: this.formValue.fbPageName,
                    isExternalUser: true,
                    externalUserFullName: this.formValue.fullName,
                    externalUserEmail: this.formValue.email,
                    externalUserPhoneNumber: parseInt(this.formValue.phoneNumber),
                    externalUserMessage: this.formValue.message,
                    externalUserFacebookPageName: this.formValue.fbPageName,
                    externalUserLongLivedUserAccessToken: this.formValue.longLivedUserAccessTokenResponse.data.accessToken,
                    externalUserPageAccessToken: this.formValue.fbPageAccessToken,
                    externalUserFacebookUserId: this.formValue.fbUserId,
                    companyName: this.formValue.companyName,
                    billingAddress: this.formValue.billingAddress,
                    postalCode: this.formValue.postalCode,
                    city: this.formValue.city
                }

                this.newEasyFacebookWidget = newEasyFacebook

                SomeWebApiEndpoints.addNewEasyBooking(newEasyFacebook)
                .then(response => {
                    
                    if(response.data.success) {
                        if(response.data.data.emailSent && response.data.data.status == "Added") {
                            // Success!!!
                            this.submitSuccess = true 
                            this.disableSubmit = true
                            this.$emit('formValueChange', this.formValue.isFormSubmittet = true)
                            
                            this.fetchPostsAndCreateWidget(newEasyFacebook)

                        } else {
                            this.submitError = 'Det er sket en fejl - prøv igen ' + response.data
                            this.disableSubmit = false                            

                            if(this.submitError != '') {
                                this.isLoading = false
                            }
                        }
                    }                    
                    else {
                        
                        this.submitError = 'Det er sket en fejl - prøv igen ' + response.data

                        if(this.submitError != '') {
                            this.isLoading = false
                        }
                    }
                })                
            }

            if(this.v$.$error) {
                this.isLoading = false
            }
  		},
        fetchPostsAndCreateWidget(newEasyFacebook) {
            this.isLoadingFetchingPosts = true

            SomeWebApiEndpoints.createWidget(newEasyFacebook)
            .then(response => {
                var data = response.json()

                data.then((result) => {
                    if(result.success) {
                    
                    this.widgetCreated = true
                    this.easyFacebookConfig.easyFacebookApiKey = result.data.widgetApiKey
                    this.createWidgetError = false   
                    
                    if(this.widgetCreated) {
                        this.isLoadingFetchingPosts = false
                    }
                }
                else {

                    console.log(result)
                    // Error - try again?
                    this.createWidgetError = true

                    if(this.createWidgetError) {
                        this.isLoadingFetchingPosts = false
                    }
                }
                })
            })  
        }
    },
    validations() {
        return {
            formValue: {
                fullName: { required, minLength: minLength(6) },
                email: { required, email},
                phoneNumber: { required, numeric },
                companyName: { required },
                billingAddress: { required },
                postalCode: { required },
                city: { required },
            },
        };
    },
}
</script>

<style>

</style>