<template>
      <div class="row">
       <h3>EasyFacebook</h3>
       <p class="mt-2">
         Med EasyFacebook kan I få vist jeres nyheder og opslag fra Facebook, direkte på jeres hjemmeside. Det er nemt og hurtigt både at bestille og lægge ind på hjemmesiden. Ved bestilling modtager I en lille kodeblok som nemt kan lægges ind på de fleste typer hjemmesider.
       </p>
      </div>
      <div class="row m-4 d-flex justify-content-center">
        <div class="row widget-row-front">
          <div class="col-md-8">
          <h5 class="mb-4 mt-2 mb-2">Bestil EasyFacebook nu</h5>
          <p></p>
          <p></p>
          </div>
          <div class="col-md-4 mt-2 mb-2 d-flex align-items-center justify-content-center">
           <router-link :to="{ name: 'RegisterFacebookPageEntry' }">
          <div class="btn btn-success"><h5 class="ps-5 pe-5 pt-1">Bestil nu</h5></div>
          </router-link>
        </div>
        </div>        
        <div class="row widget-row-front mt-4">
        <div class="col-md-8">
          <h5 class="mb-4 mt-2 mb-2">Har du allerede EasyFacebook?</h5>
          <p>Er adgangen til Facebook ikke længere gyldig, eller ønser du at trække adgangen tilbage og opsige EasyFacebook kan i ændre adgangen her.</p>
        </div>        
        <div class="col-md-4 mt-2 mb-2 d-flex align-items-center justify-content-center">
          <router-link :to="{ name: 'RequestNewAccessToken' }">
          <div class="btn btn-success"><h5 class="ps-5 pe-5 pt-1">Opdater</h5></div>
          </router-link>
        </div>
      </div>
      </div>
       
      <div class="row mt-5">
        <h5 class="mb-3">Demo</h5>
        <div class="widget-row">
          
          <EasyFacebookWidgetDemo :easyFacebookConfig="easyFacebookConfig" />
          <!-- <div class="btEmbeddedWidget" data-btcontentid="0c039bcd-5677-40a0-a035-f0512b83e902" data-btsettings-facebookkey="0d3d7dcc-dcd7-4034-98d3-bef47c0a6d28" data-btsettings-cornertype="round" data-btsettings-color="#01a1b7" data-btsettings-backgroundcolor="#f2f2fa" data-btsettings-limit="10"></div> -->
     
    </div>
      </div>      
</template>

<script>
import EasyFacebookWidgetDemo from '../components/EasyFacebookWidgetDemo.vue'

export default {
  name: 'Home',
  title: "Auto IT: EasyFacebook",
  components: {
    EasyFacebookWidgetDemo
  },
  data() {
    return {
      easyFacebookConfig: {
            easyFacebookEnv: 'Production',
            easyFacebookApiKey: '0d3d7dcc-dcd7-4034-98d3-bef47c0a6d28'
        }
    }
  }
  // beforeMount() {
  //   const s = document.createElement('script');
  //            s.type = 'text/javascript';
  //            s.src = 'https://services.autoit.dk/Embed.js';
  //            s.id = "prod_script"
  //            document.body.appendChild(s);
  // }
}
</script>
