import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound.vue'
import RegisterFacebookPageEntry from '../views/RegisterFacebookPageEntry.vue'
import RequestNewAccessToken from '../views/RequestNewAccessToken.vue'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/registerfacebookpage',
    name: 'RegisterFacebookPageEntry',
    component: RegisterFacebookPageEntry
  },
  {
    path: '/requestnewaccesstoken',
    name: 'RequestNewAccessToken',
    component: RequestNewAccessToken
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
