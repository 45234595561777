<template>
    <easy-facebook 
        backgroundcolor="#f2f2fa" 
        color="#01a1b7" 
        :facebookkey="easyFacebookConfig.easyFacebookApiKey"
        >
    </easy-facebook>
</template>

<script>
export default {
    name: 'EasyFacebook Widget Demo',
    props: {
        easyFacebookConfig: {
            easyFacebookEnv: String,
            easyFacebookApiKey: String
        }
    },
    mounted() {

        // if(this.easyFacebookConfig.easyFacebookEnv === 'Development') {
        //     const s = document.createElement('script');
        //     s.type = 'text/javascript';
        //     s.src = 'https://scripts.apps.devweb.autoit.dk/s/easyfacebook.umd.js';
        //     s.id = "dev_script"
        //     document.body.appendChild(s);
        // }

        // if(this.easyFacebookConfig.easyFacebookEnv === 'Production') {
        //     const s = document.createElement('script');
        //     s.type = 'text/javascript';
        //     s.src = 'https://scripts.utility.biltorvetweb.dk/s/easyfacebook.umd.js';
        //     s.id = "prod_script"
        //     document.body.appendChild(s);
        // }
    },
}
</script>

<style>

</style>