<template>
  <div class="mb-2">
      <div class="col-md-4">
        <label class="label label-default">Email brugt til at registrere EasyFacebook*</label>
        <input
        v-model="v$.emailInput.$model"
        type="email" 
        placeholder="" 
        name="emailInput" 
        id="emailInput"
        class="form-control shadow-none mb-2"
        />
        <div class="input-errors mt-2" v-for="error of v$.emailInput.$errors" :key="error.$uid">
            <div class="alert alert-danger">{{ error.$message }}</div>
        </div>
        <p v-if="submitError" class="alert alert-danger">
            {{ submitError }}
        </p>
        <p v-if="submitSuccess" class="alert alert-success">
            {{ submitSuccess }}  
        </p>
        <button @click="submitForm" :disabled="isLoading" class="btn custom-btn">Send <easy-spinner v-if="isLoading" /></button>
      </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import SomeWebApiEndpoints from '../services/some-web-api-service'

export default {
    name: 'SendEmail',
    props: [
        'setView'
    ],
    data() {
        return {
            v$: useValidate(),
            emailInput: '',
            submitError: '',
            submitSuccess: '',
            isLoading: false
        }
    },
    methods: {     
        submitForm() {
            this.isLoading = true
            this.submitFormValidate() 
        },  
        submitFormValidate() {           

            this.v$.$validate()
            if (!this.v$.$error) {

                SomeWebApiEndpoints.requestToken(this.emailInput, this.setView)
                .then(response => {

                    if(response.data.success === true) {
                        this.submitSuccess = this.setView == "RequestNewAccessToken"? 'Hvis email adressen findes i vores system modtager du om lidt en mail med oplsyninger om hvordan du kan give os adgang til en ny Page Access Token / trække adgange tilbage.' : "Hvis email adressen findes i vores system modtager du om lidt en mail med oplsyninger om hvordan du kan trække samtykket tilbage."
                        this.isLoading = false

                    } else {
                        this.submitError = 'Noget gik galt ' + response.data.errors
                        this.isLoading = false
                    }
                })                
            }
            else if(this.v$.$error) {
                this.isLoading = false
            }
        },
    },
    validations() {
        return {
            emailInput: { required, email},
        };
  }
}
</script>

<style>

</style>