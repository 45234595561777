<template>
    <div v-if="setView === 'RequestNewAccessToken'">
        <p>Du skal logge ind for at give adgang til en ny Page Access Token eller trække adgangen tilbage.</p>
    </div>
    <div>
        <span>
            <button v-if="isLoggedIn" :disabled="isLoading" class="btn btn-facebook" @click="logoutWithFacebook">
                    <i class="fa fa-facebook mr-1"></i>
                    <span>Træk adgang til Facebook tilbage (log ud) <easy-spinner v-if="isLoading" /></span>
            </button>
            
            <button v-if="!isLoggedIn" :disabled="isLoading" class="btn btn-facebook" @click="loginWithFacebook">
                    <i class="fa fa-facebook mr-1"></i>
                    <span>Forbind til Facebook <easy-spinner v-if="isLoading" /></span>
            </button>
        </span>
        <div>
            <p v-if="facebookLoginError" class="alert alert-danger mt-3">Der er desværre gået noget galt. Prøv venligst igen.</p>
        </div>
    </div>
    <div v-if="hasBeenLoggedOut && !isLoggedIn">
        <p class="alert alert-success mt-2">Alle adgange er nu blevet trukket tilbage og vi kan ikke længere hente og opdatere opslag fra jeres Facebook side.</p>
    </div>
    <div v-if="setView === 'RequestNewAccessToken' && facebookPageFound" class="mt-5">
        <p><b>Ny Page Access Token</b></p>
        <p>Følgende Facebook side blev fundet: <b>{{ facebookPage.pageName }}</b></p>
        <p v-if="successUpdatingPageAccessToken" class="alert alert-success">Page Access Token opdateret.</p>
        <button @click="updatePageAccessToken" class="btn custom-btn mt-2" >Opdatér Page Access Token <easy-spinner v-if="isLoadingUpdate" /></button>
    </div>
</template>

<script>
import SomeWebApiEndpoints from '../services/some-web-api-service'

export default {
    name: 'FacebookLoginDialog',
    beforeMount() {
        var fld = this;

        window.FB.getLoginStatus(({ authResponse }) => {
            if (authResponse) {
                FB.logout()
                fld.isLoggedIn = false
            } else {
                fld.isLoggedIn = false
            }
        });

        this.token = this.$route.query.token
    },
    props: [
        'setView'
    ],
    data() {
        return {
            isLoggedIn: false,
            hasBeenLoggedOut: false,
            facebookLoginError: false,
            fbUserId: '',
            shortLivedUserAccessToken: '',
            longLivedUserAccessToken: '',
            facebookPageId: '',
            token: '',
            facebookPage: {},
            facebookPageFound: false,
            errorWithFacebookPage: '',
            newFacebookPageAccessToken: '',
            successUpdatingPageAccessToken: false,
            isLoading: false,
            isLoadingUpdate: false
        }
    },
    methods: {
        loginWithFacebook() {

            this.isLoading = true

            var fld = this;

            window.FB.login(response => {
                if (response && response.authResponse) {
                    if(response.status === 'connected') {
                        fld.facebookLoginError = false                    
                        fld.isLoggedIn = true
                        fld.fbUserId = response.authResponse.userID
                        fld.shortLivedUserAccessToken = response.authResponse.accessToken

                        if(fld.setView == "RequestNewAccessToken") {
                            fld.fetchLongLivedAccessTokenAndManangedPages()                            
                        }
                    }
                    else {
                        fld.facebookLoginError = true
                    }
                }
                else {
                    fld.facebookLoginError = true
                }

                fld.isLoading = false
                
            }, {
                scope: 'pages_show_list,pages_read_engagement,pages_read_user_content',
                return_scopes: true
            })
        },
        logoutWithFacebook() {
            
                this.isLoading = true
               
                FB.logout()

                this.facebookPageFound = false
                this.hasBeenLoggedOut = true
                this.isLoggedIn = false
                this.isLoading = false
        },
        fetchLongLivedAccessTokenAndManangedPages() {
            SomeWebApiEndpoints.getLongLivedUserAccessToken(this.shortLivedUserAccessToken)
                .then(response => {
                    if(response.data.success == true) {
                        this.longLivedUserAccessToken = response.data.data.accessToken

                        this.fetchManagedPages()
                    } 
                })
        },
        fetchManagedPages() {
            // TODO: Refactor!
            // Duplicate code and additional call to API - Let's just be done with this already!
            SomeWebApiEndpoints.isTokenValid(this.token, "0")
            .then(response => {
                
                if(response.data.success == true)
                {
                    if(response.data.data.isTokenValid && !response.data.data.isTokenExpired) {
                        
                        this.facebookPageId = response.data.data.facebookPage.pageId
                    }
                }
            })

            if(this.longLivedUserAccessToken && this.fbUserId) {
                SomeWebApiEndpoints.getAllManagedPages(this.longLivedUserAccessToken, this.fbUserId)
                .then(response => {
                    if(response.data.success == true) {
                        
                        var page = response.data.data.find(page => page.pageId === this.facebookPageId)
                        
                        if(page != null) {
                            
                            this.facebookPage = page
                            this.facebookPageFound = true
                            this.newFacebookPageAccessToken = page.accessToken
                        }
                    }
                })  
            }            
        },
        updatePageAccessToken() {
            
            this.isLoadingUpdate = true
            
            SomeWebApiEndpoints.updateFacebookPage(this.facebookPageId, this.newFacebookPageAccessToken)
            .then(response => {
                //console.log(response)

                if(response.status === 200) {
                    this.successUpdatingPageAccessToken = true

                    this.isLoadingUpdate = false
                }

                this.isLoadingUpdate = false
            })
        }
    },
}
</script>

<style>

</style>