import axios from 'axios';

//const baseUrlDev = 'https://localhost:44367';
//const baseUrlDev = 'https://some-api-staging.autoitweb.dk';
const baseUrlProd = 'https://some-api.autoitweb.dk'

const baseUrl = baseUrlProd;

class SomeWebApiEndpoints {
    // Fetch a long-lived user access token
    getLongLivedUserAccessToken(userAccessToken){
        return axios.post(baseUrl + '/facebookpagefeed/accesstoken/longliveduseraccesstoken',
        {
            UserAccessToken: userAccessToken
        })
    }

    // Get all managed pages (permission: pages_show_list)
    getAllManagedPages(longLivedUserAccessToken, userId) {
        return axios.post(baseUrl + '/facebookpagefeed/managedpages',
        {
            FacebookUserId: userId,
            LongLivedUserAccessToken: longLivedUserAccessToken
        })
    }

    // Check if the PageId has already been added to the DB
    checkIfFacebookPageIdExist(pageId) {
        return axios.get(baseUrl + '/facebookpagefeed/fbpageinfo/pageidexists?pageId=' + pageId)
    }

    // Submit form - add FacebookPage to our DB
    addNewEasyBooking(newEasyFacebook) {
        return axios.post(baseUrl + '/facebookpagefeed/feed',
        {
            PageId: newEasyFacebook.pageId,
            Name: newEasyFacebook.name,
            IsExternalUser: true,
            ExternalUserFullName: newEasyFacebook.externalUserFullName,
            ExternalUserEmail: newEasyFacebook.externalUserEmail,
            ExternalUserPhoneNumber: parseInt(newEasyFacebook.externalUserPhoneNumber),
            ExternalUserMessage: newEasyFacebook.externalUserMessage,
            ExternalUserFacebookPageName: newEasyFacebook.externalUserFacebookPageName,
            ExternalUserLongLivedUserAccessToken: newEasyFacebook.externalUserLongLivedUserAccessToken,
            ExternalUserPageAccessToken: newEasyFacebook.externalUserPageAccessToken,
            ExternalUserFacebookUserId: newEasyFacebook.externalUserFacebookUserId,
            ExternalUserIsDisabled: false,
            CompanyName: newEasyFacebook.companyName,
            BillingAddress: newEasyFacebook.billingAddress,
            PostalCode: newEasyFacebook.postalCode,
            City: newEasyFacebook.city,

        }).catch((error) => {
            return error.response
        })
    }

    // Sends a token to the user if he / she wants to give access to a new page token or withdraw permissions
    requestToken(email, view, facebookPage) {
        return axios.post(baseUrl + "/facebookpagefeed/token/requesttoken",
        {
            Email: email,
            View: view,
            FacebookPage: facebookPage

        }).catch((error) => {
            //console.log(error.response)            
            return error.response
        })
    }

    // Check if a token is valid
    isTokenValid(token, tokenType) {
        return axios.post(baseUrl + "/facebookpagefeed/token/istokenvalid",
        {
            Token: token,
            TokenType: tokenType
            
        }).catch((error) => {
            //console.log(error.response)            
            return error.response
        })
    }

    // Patch Facebook Page
    updateFacebookPage(pageId, newFacebookPageAccessToken) {

        let newDate = new Date();

        let array = [
            {
                "op": "replace",
                "path": "/externalUserPageAccessToken",
                "value": newFacebookPageAccessToken
            },
            {
                "op": "replace",
                "path": "/updatedOn",
                "value": newDate.toISOString().split('T')[0]
            }
        ];

        return fetch(baseUrl + "/facebookpagefeed/feed/" + pageId, {
            method: 'PATCH',
            body: JSON.stringify(
                array
            ),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        }).catch((error => {
            return error.response
        }))       
    }

    // Create EasyFacebook Widget
    createWidget(newFacebookPageAccessToken) {
        return fetch(baseUrl + "/facebookpagefeed/feed/createwidget", {
            method: 'POST',
            body: JSON.stringify(
                {
                    "FacebookPageId": newFacebookPageAccessToken.pageId,
                    "ExternalUserEmail": newFacebookPageAccessToken.externalUserEmail,
                    "ExternalUserFullName": newFacebookPageAccessToken.externalUserFullName,
                    "ExternalUserPageAccessToken": newFacebookPageAccessToken.externalUserPageAccessToken
                }
            ),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        }).catch((error => {
            return error.response
        }))
    }

    // Resend EasyFacebook Widget Info
    resendWidgetInfo(pageId, pageName, email) {
        return fetch(baseUrl + "/facebookpagefeed/fbpageinfo/resendwidgetinfo", {
            method: 'POST',
            body: JSON.stringify(
                {
                    "FacebookPageId": pageId,
                    "FacebookPageName": pageName,
                    "Email": email
                }
            ),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        }).catch((error => {
            return error.response
        }))
    }
}

export default new SomeWebApiEndpoints();