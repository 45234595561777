<template>
<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <router-link :to="{ name: 'Home' }" class="navbar-brand">
      <img alt="Auto IT log" src="./assets/autoit_logo.png" width="250" height="73">
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link :to="{ name: 'RegisterFacebookPageEntry' }" class="nav-link">Opret EasyFacebook</router-link>
        </li>
        <li class="nav-item">
          <a href="/requestnewaccesstoken" class="nav-link">Ny Page Access Token / Træk adgang tilbage</a>
        </li>
      </ul>
     
    </div>
  </div>
</nav>
  
</div>
<div class="container bg-white formArea">
    <router-view/>  
</div>
<div class="container">
  <div class="row">
    <div class="text-start mb-5">
  <a href="https://www.autoit.dk" target="_blank">Auto IT</a> | 
  <a href="https://www.autoit.dk/wp-content/uploads/Forretningsbetingelser-Auto-IT.pdf" target="_blank">Forretningsbetingelser</a>

</div>
 </div>
</div>
</template>
<script>
export default {
  name: 'App',
  meta: {
    title: "Auto IT: EasyFacebook"
  }
}
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@import "./assets/style.css";

</style>
