<template>
    <div >
        <p>404 Page not Found</p>
    </div>
</template>

<script>
export default {
  name: 'page_not_found'
}
</script>