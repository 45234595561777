import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { initFacebookSdk } from './helpers/init-facebook-sdk.js'
import router from './router'
import App from './App.vue'
import easySpinner from 'vue-easy-spinner';

initFacebookSdk().then(startApp);

function startApp() {
    createApp(App)
        .use(easySpinner, {
            prefix: 'easy',
        })
        .use(router)
        .mount('#app');
}