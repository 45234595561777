<template>
    <div class="text-start mb-3">
        
        <h3>Bestil EasyFacebook</h3>
        <div class="mb-5">
            <p>
                For at vi kan lave opsætningen af EasyFacebook, har vi brug for adgang til den side du ønsker at få vist opslag fra. Du skal være administrator på den Facebook side i ønsker at få vist opslag fra, og logge ind på facebook her på siden og give Auto IT adgang til at hente jeres opslag.
            </p>
            <p>
                Vi beder om adgang til at se hvilke sider der administreres og til at se opslag på siden. Vi overfører intet andet end opslag og tilhørende indhold via den adgang I giver til Auto IT.
            </p>
            <p>
                Når du er logget ind og kan du vælge den side du ønsker at få vist opslag fra. Du kan til hver en tid trække samtykket tilbage hvorefter vi ikke længere har adgang til at hente opslag fra siden. 
                Opdatering eller sletning af eksisterende adgang kan gøres <router-link :to="{ name: 'RequestNewAccessToken' }">her</router-link>.
            </p>
        </div>
        <div>
             <span>
            <button v-if="formValue.isLoggedIn" :disabled="isLoading" class="btn btn-facebook" @click="logoutWithFacebook">
                    <i class="fa fa-facebook mr-1"></i>
                    <span>Log ud af Facebook <easy-spinner v-if="isLoading" /></span>
            </button>
           
            <button v-if="!formValue.isLoggedIn" :disabled="isLoading" class="btn btn-facebook" @click="loginWithFacebook">
                    <i class="fa fa-facebook mr-1"></i>
                    <span>Forbind til Facebook  <easy-spinner v-if="isLoading" /></span>
            </button>
            </span>
            <div v-if="isLoading && !facebookLoginError" class="col-md-4">
                <p class="alert alert-info mt-2">Forbinder til Facebook vent venligst...</p>
            </div>
            <div v-if="formValue.isLoggedIn && this.formValue.managedPages.data.length > 0" class="col-md-4">
                <p class="alert alert-success mt-2">Du er nu forbundet til Facebook.</p>
            </div>
            <div>
                <p v-if="facebookLoginError" class="alert alert-danger mt-2 col-md-4">Der er desværre gået noget galt. Prøv venligst igen.</p>
            </div>
        </div>
    </div>
    <br>
    <h5 class="mb-3">Vælg en Facebook side for at fortsætte bestillingen</h5>
    <div v-if="formValue.managedPages.data.length >= 1">

        <p>Du skal nu vælge den Facebookside du ønsker at vise opslag fra. <b>Tryk på den side du ønsker at vælge:</b></p>

        <div v-if="errorSelectingPage" class="alert alert-info"><p>Du har forsøgt at valge <b>{{ this.resendWidgetPageName }}</b>, men vi har allerede adgang til siden. Du kan få sendt opsætningsvejledning til <b>{{ this.fbLoginEmailAdr }}</b> eller vælge en anden side.</p>
            <button class="btn btn-success mt-3" @click="FbPageExistsResendCode">Gensend opsætning <easy-spinner v-if="isLoadingResendWidgetInfo" /></button>
        </div>
        <p v-if="resendWigetInfoSucces" class="alert alert-success">Opsætningsvejledningen er blevet sendt</p> 
        <p v-if="resendWidgetInfoError" class="alert alert-danger">Der skete en fejl - prøv venligst igen</p>

        <div v-if="formValue.haveAPageBeenSelected">
            <p class="alert alert-success">Du har valgt {{ formValue.fbPageName }} og er nu klar til at forsætte.</p>
        </div>

        <div v-for="(page, i) in formValue.managedPages.data" :key="i" class="pages">
            
            <p @click="addRemovePage(page, i)" class="page" :class="{selected: formValue.fbPageId === page.pageId, 'page-blink': formValue.haveAPageBeenSelected === false}" style="cursor: pointer;">{{ page.pageName }}</p>
 
        </div>

        <easy-spinner v-if="isLoadingManagedPages" class="ms-2" />

    </div>
    <div v-else>
        <p v-if="!noManagedPages" class="alert alert-danger col-md-6">Du skal forbinde til Facebook før du kan vælge den side vi skal vise opslag fra. Husk at give os de efterspurgte adgange. Når du er logget ind henter vi automatisk de sider du har adgang til.</p>
        
        <div v-if="noManagedPages" >
            <p class="alert alert-danger">Vi kunne desværre ikke finde nogen Facebook sider - tjek venligst om du har administrator adgang til den side du ønsker at vise opslag fra og at du har givet os de efterspurgte adgange. Hvis du mener det er en fejl kan du prøve igen.</p>
            <button @click="tryFetchingPagesAgain" class="btn custom-btn">Prøv igen</button>

    </div>
    <div v-if="isLoadingManagedPages" class="col-md-4">
        <p class="alert alert-info mt-2">Henter Facebook Sider vent venligst... </p>
        
    </div>
    </div> 
    <br>
</template>

<script>
import SomeWebApiEndpoints from '../../services/some-web-api-service'

    export default {
        name: 'ConnectToFacebook',
        beforeMount() {
            var fld = this;

            window.FB.getLoginStatus(({ authResponse }) => {
                if (authResponse) {
                    FB.logout()
                    fld.isLoggedIn = false
                } else {
                    fld.isLoggedIn = false
                }
            });

        },        
        props: {
            formValue: {
                fbUserId: String,                
                fbPageName: String,
                fbPageId: String,
                fbPageAccessToken: String,
                isFbPageSelected: Boolean,
                fullName: String,
                email: String,
                phoneNumber: Number,
                message: String,
                companyName: String,
                billingAddress: String,
                postalCode: String,
                city: String,
                fbPageName: String,
                
                managedPages: {
                    data: Array,
                    success: Boolean,
                    message: String
                },
                longLivedUserAccessTokenResponse: {
                        data: {
                            accessToken: String
                        },
                        success: Boolean,
                        message: String
                },
                isLoggedIn: Boolean,
                shortLivedUserAccessToken: String,
                haveAPageBeenSelected: Boolean,
                isFormSubmittet: Boolean,
                }     
        },
        data() {
            return {
                errorSelectingPage: '',
                doesPageExist: false,
                noManagedPages: false,
                facebookLoginError: false,
                isLoading: false,
                isLoadingManagedPages: false,
                isLoadingResendWidgetInfo: false,
                fbLoginEmailAdr: '',
                resendWigetInfoSucces: false,
                resendWidgetInfoError: false,
                resendWidgetPageName: '',
                resendWidgetPageId: '',
            }
        },
        emits: ['formValueChange'],
        methods: {            
            loginWithFacebook() {

                this.isLoading = true
                this.facebookLoginError = false

                var ctf = this;

                window.FB.login(function(response) {
                    
                    if (response && response.authResponse) {
                        if(response.status === 'connected') {

                            console.log(response)

                            FB.api('me?fields=email', 'get', { access_token : response.authResponse.accessToken}, function(response) {
                                ctf.fbLoginEmailAdr = response.email;

                                console.log(response.email)
                                console.log(response)
                            })
                           
                            ctf.$emit('formValueChange', ctf.formValue.haveAPageBeenSelected = false)
                            ctf.$emit('formValueChange', ctf.formValue.fbPageAccessToken = '')
                            ctf.$emit('formValueChange', ctf.formValue.fbUserId = '')
                            ctf.$emit('formValueChange', ctf.formValue.fbPageId = '')
                            ctf.$emit('formValueChange', ctf.formValue.shortLivedUserAccessToken = '')
                            ctf.$emit('formValueChange', ctf.formValue.managedPages.data.length = 0)
                            ctf.$emit('formValueChange', ctf.formValue.isFbPageSelected = false)
                            ctf.noManagedPages = false
                            ctf.errorSelectingPage = ''
                            ctf.haveAPageBeenSelected = false

                            ctf.facebookLoginError = false              
                            ctf.$emit('formValueChange', ctf.formValue.isLoggedIn = true)
                            ctf.$emit('formValueChange', ctf.formValue.fbUserId = response.authResponse.userID)
                            ctf.$emit('formValueChange', ctf.formValue.shortLivedUserAccessToken = response.authResponse.accessToken)

                            ctf.fetchLongLivedAccessTokenAndManangedPages()                           
                        }
                        else {
                            ctf.facebookLoginError = true
                            ctfvm.isLoading = false
                        }                        
                    }
                    else {
                            ctf.facebookLoginError = true
                            ctf.isLoading = false
                        }                    
                }, {
                    scope: 'email,pages_show_list,pages_read_engagement,pages_read_user_content',
                    return_scopes: true
                })
            },
            logoutWithFacebook() {

                this.isLoading = true
                this.isLoadingManagedPages = false
                
                FB.logout(function(response) {
                    
                });
                
                this.$emit('formValueChange', this.formValue.isLoggedIn = false)
                this.$emit('formValueChange', this.formValue.fbPageAccessToken = '')
                this.$emit('formValueChange', this.formValue.fbUserId = '')
                this.$emit('formValueChange', this.formValue.shortLivedUserAccessToken = '')
                this.$emit('formValueChange', this.formValue.managedPages.data.length = 0)
                this.$emit('formValueChange', this.formValue.isFbPageSelected = false)
                this.noManagedPages = false
                this.errorSelectingPage = ''

                this.isLoading = false    
            },
            tryFetchingPagesAgain(){
                this.isLoading = true

                this.fetchLongLivedAccessTokenAndManangedPages()
            },
            addRemovePage(page, i) {

                this.isLoadingManagedPages = true

                this.errorSelectingPage = ''
                               
                if(this.formValue.managedPages.data) {

                    if(!this.formValue.haveAPageBeenSelected && !page.isSelected) {

                        // Check if PageId exists - if it does let the user know and don't select it
                        this.checkIfPageIdExist(this.formValue.managedPages.data[i].pageId, i, page)                          
                    }
                    else if(page.isSelected) {

                        this.$emit('formValueChange', this.formValue.managedPages.data[i].isSelected = false)
                        this.$emit('formValueChange', this.formValue.haveAPageBeenSelected = false)
                        this.$emit('formValueChange', this.formValue.fbPageName = '')
                        this.$emit('formValueChange', this.formValue.fbPageId = '')
                        this.$emit('formValueChange', this.formValue.fbPageAccessToken = '')
                        this.$emit('formValueChange', this.formValue.isFbPageSelected = false)                        

                        if(this.errorSelectingPage != '' || !this.formValue.haveAPageBeenSelected) {
                            
                            this.isLoadingManagedPages = false
                        }                        
                    }                    
                    else if (this.formValue.haveAPageBeenSelected) {
                        this.errorSelectingPage = ''

                        if(this.errorSelectingPage != '' || this.formValue.haveAPageBeenSelected) {

                            this.isLoadingManagedPages = false
                        }
                    }
                }
            },
            fetchLongLivedAccessTokenAndManangedPages() {

                // Call our API to get a Long Lived Access Token (We need a short-lived User Access Token to do so)
                SomeWebApiEndpoints.getLongLivedUserAccessToken(this.formValue.shortLivedUserAccessToken)
                .then(response => {
                    this.$emit('formValueChange', this.formValue.longLivedUserAccessTokenResponse.success = response.data.success)
                    this.$emit('formValueChange', this.formValue.longLivedUserAccessTokenResponse.data.accessToken = response.data.data.accessToken)

                    // If we got the Long Lived Access Token we can request a list of managed pages
                    if(this.formValue.longLivedUserAccessTokenResponse.success) {
                        
                        this.fetchManagedPages()                       
                    }
                })
            },
            fetchManagedPages() {
                
                // Request a list of managed pages - takes the Long-Lived User Access Token (Because we want Page Access Tokens than never expired) and a UserID
                SomeWebApiEndpoints.getAllManagedPages(this.formValue.longLivedUserAccessTokenResponse.data.accessToken, this.formValue.fbUserId)
                        .then(response => {
                            this.$emit('formValueChange', this.formValue.managedPages.success = response.data.success)
                            
                            if(this.formValue.managedPages.success) {
                                    
                                if(response.data.data.length == 0) {
                                    this.noManagedPages = true
                                }
                                else {
                                    response.data.data.forEach(page => {
                                        let newPage = {
                                            pageName: page.pageName,
                                            pageId: page.pageId,
                                            accessToken: page.accessToken,
                                            isSelected: false
                                        }

                                        this.$emit('formValueChange', this.formValue.managedPages.data.push(newPage))

                                    })
                                }                                
                            }
                        })
                        
                        this.isLoading = false
            },
            checkIfPageIdExist(pageId, i, page) {

                SomeWebApiEndpoints.checkIfFacebookPageIdExist(pageId)
                    .then(response => {

                        this.doesPageExist = response.data.data == null ? false : true

                        // It's already been added - error!
                        if(this.doesPageExist) {
                                                        
                            this.$emit('formValueChange', this.resendWidgetPageName = page.pageName)
                            this.$emit('formValueChange', this.resendWidgetPageId = page.pageId)
                            
                            this.errorSelectingPage = "Facebook siden er allerede tilføjet - vælg venligst en anden."                            
                        }

                        // Page does not exist - let's select it!
                        else {

                            this.errorSelectingPage = ''

                            // Updated props
                            this.$emit('formValueChange', this.formValue.managedPages.data[i].isSelected = true)
                            this.$emit('formValueChange', this.formValue.haveAPageBeenSelected = true)
                            this.$emit('formValueChange', this.formValue.fbPageName = page.pageName)
                            this.$emit('formValueChange', this.formValue.fbPageId = page.pageId)
                            this.$emit('formValueChange', this.formValue.fbPageAccessToken = page.accessToken)
                            this.$emit('formValueChange', this.formValue.isFbPageSelected = true)
                        }

                            if(this.errorSelectingPage != '' || this.formValue.haveAPageBeenSelected) {

                                this.isLoadingManagedPages = false

                            }
                    })
            },
            FbPageExistsResendCode() {
                 this.isLoadingResendWidgetInfo = true

                SomeWebApiEndpoints.resendWidgetInfo(this.resendWidgetPageId, this.resendWidgetPageName, this.fbLoginEmailAdr)
                .then(response => {
                    var data = response.json()

                    data.then((result) => {
                        if(result.success) {
                        
                        this.resendWigetInfoSucces = true

                        if(this.resendWigetInfoSucces) {
                            this.isLoadingResendWidgetInfo = false
                        }
                    }
                    else {
                        console.log(result)
                        // Error - try again?
                        this.resendWidgetInfoError = true

                        if(this.resendWidgetInfoError) {
                            this.isLoadingResendWidgetInfo = false
                        }
                    }
                    })
                })  
            }
        },    
    }
</script>

<style>

</style>