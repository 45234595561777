<template>
  
    <component 
      :is="steps[currentStep].component"
      :formValue="formValue[steps[currentStep].label]"
       @formValueChange="updateFormValue"
    />
    <div class="mb-4">

    <button v-if="currentStep !== 0" @click="previousStep" class="btn custom-btn">Tilbage</button> <button v-if="currentStep < steps.length -1 && formValue.registerEasyFacebook.isFbPageSelected" @click="nextStep" class="btn custom-btn" :class="{cantContinue: !formValue.registerEasyFacebook.isFbPageSelected}">Fortsæt</button>
    </div>
    <br>
</template>

<script>
import { shallowRef } from 'vue'
import ConnectToFacebook from './steps/ConnectToFacebook.vue'
import ContactInformation from './steps/ContactInformation.vue'

export default {
    
    name: 'RegisterFacebookPage',
    components: {
      ConnectToFacebook,
      ContactInformation
    },
    data() {
      return {
        submitted: false,
        currentStep: 0,        
        formValue: {          
          registerEasyFacebook : {
            fbUserId: '',            
            fbPageName: '',
            fbPageId: '',
            fbPageAccessToken: '',
            isFbPageSelected: false,
            fullName: '',
            email: '',
            phoneNumber: null,
            message: '',
            companyName: '',
            billingAddress: '',
            postalCode: '',
            city: '',
            managedPages: {
                    data: [],
                    success: '',
                    message: ''
            },
            longLivedUserAccessTokenResponse: {
                    data: {
                        accessToken: ''
                    },
                    success: '',
                    message: ''
            },
            isLoggedIn: false,
            shortLivedUserAccessToken: '',
            haveAPageBeenSelected: false,
            isFormSubmittet: false
          }
        },
        steps: [          
          {
            label: 'registerEasyFacebook',
            componentData: '',
            component: shallowRef(ConnectToFacebook)
          },
          {
            label: 'registerEasyFacebook',
            componentData: '',
            component: shallowRef(ContactInformation)
          }
        ],
      }
    },    
    methods: {
      nextStep() {
          this.currentStep += 1
               
      },
      previousStep() {
        this.currentStep -= 1
      },
      updateFormValue(payload) {
      this.formValue = {
        ...this.formValue,
        [payload.label]: payload.data
        }     
      }    
    },
}
</script>

<style>

</style>