<template>
    <div>
        <RegisterFacebookPage />
  </div>

</template>

<script>
import RegisterFacebookPage from "../components/RegisterFacebookPage.vue"

export default {
  name: 'Home',
  components: {
    RegisterFacebookPage
  }
}
</script>
