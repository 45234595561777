<template>
  <div class="text-start mb-3">
    <h3>Ny Page Access Token / Træk adgang tilbage</h3>
  </div>
  <div v-if="!isTokenPresent">
    <br>
    <div>
      <p><b>Ny Page Access Token</b></p>
    <p>
      Hvis jeres nuværende Page Access Token er blevet ugyldig, kan vi ikke længere hente og opdatere opslag fra jeres Facebook side.
    </p>
    <br>
    <p><b>Træk adgang tilbage</b></p>
    <p>
      Skal vi ikke længere have adgang til at hente og vise opslag fra jeres Facebook Side har i mulighed for at trække adgangen tilbage (Bemærk: Har vi ikke længere adgang kan vi ikke hente de nyeste opslag eller opdatere de gamle)
    </p>
    <br>
    <ol>
      <li>
      Indtast den mail der er blevet brugt til at oprette jeres EasyFacebook (Er du i tvivl om hvilken email adresse det er kan du skrive en mail til web@autoit.dk).
      </li>
      <li>
       Du modtager en email med et link, hvor du kan forbinde til Facebook og give os adgang til en ny Page Access eller trække adgang tilbage.
      </li>
    </ol>
  </div>  
  <SendMail :setView="setView" />
  </div>
  <div v-else-if="isTokenPresent && isTokenValid">
    <FacebookLoginDialog :setView="setView" />
  </div>
  <div v-else>
    <p class="alert alert-danger">
      Dit link er enten udløbet eller ugyldigt. Du kan få et ny <a href="/requestnewaccesstoken"><b>her</b></a>
    </p>
  </div>
</template>

<script>
import SendMail from '../components/SendMail.vue'
import FacebookLoginDialog from '../components/FacebookLoginDialog.vue'
import SomeWebApiEndpoints from '../services/some-web-api-service'

export default {
  name: 'RequestNewAccessToken',
  components: {
    SendMail,
    FacebookLoginDialog
  },
  beforeMount() {
    this.isTokenPresent = this.$route.query.token != null ? true : false

      if(this.isTokenPresent) {
        this.token = this.$route.query.token

        SomeWebApiEndpoints.isTokenValid(this.token, "0")
          .then(response => {
              if(response.data.success == true)
              {
                if(response.data.data.isTokenValid && !response.data.data.isTokenExpired) {
                    this.isTokenValid = true
                }
              }
          })
    }    
  }, 
  data() {
    return {
      setView: 'RequestNewAccessToken',
      isTokenPresent: false,      
      isTokenValid: false,
      token: ''
    }
  },
  
}
</script>

<style>

</style>